
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import configs from '@/configs'
import { setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'Mbank',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  async created () {

  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    location.replace(`${configs.passportRoot}partner?redirect=${encodeURIComponent(configs.lifeRoot)}n%2F2ONOTPD6&source=11`)
  }
}
